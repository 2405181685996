import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Expenses from "../Pages/Expenses/Expenses";
import Setting from "../Pages/Setting/Setting";
import Accounts from "../Pages/Accounts/Accounts";
import Contact from "../Pages/Contact/Contact";
import Incomes from "../Pages/Income/Incomes";
import Loan from "../Pages/Loan/Loan";
import Portfolio from "../Pages/Portfolio/Portfolio";
import Login from "../Pages/UserCredentials/Login";
import SignUp from "../Pages/UserCredentials/SignUp";
import EmailVerify from "../Pages/UserCredentials/EmailVerify";
import ForgotPassword from "../Pages/UserCredentials/ForgotPassword";
import ResetPassword from "../Pages/UserCredentials/ResetPassword";
import About from "../Pages/About/About";
import { useSelector } from "react-redux";

const Router = () => {
  const userData = localStorage.getItem("UserCredentials");
  const settingRedux = useSelector((state) => state.setting.settingDetails);
  const userRollReducer = useSelector((state) => state.userRole.state);

  // useEffect(() => {
  //   if (userData.username) {
  //     setAuth(true);
  //   }RestrictedRoute
  // }, []);

  return (
    <Routes>
      <Route
        index
        path="/"
        element={!userData ? <Navigate to="/login" /> : <Dashboard />}
      />
      <Route
        path="/contact"
        element={
          !userData ? (
            <Navigate to="/login" />
          ) : userRollReducer.contact.view_permission ? (
            <Contact />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/accounts"
        element={
          !userData ? (
            <Navigate to="/login" />
          ) : userRollReducer.account.view_permission ? (
            <Accounts />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/expenses"
        element={
          !userData ? (
            <Navigate to="/login" />
          ) : userRollReducer.expense.view_permission ? (
            <Expenses />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/incomes"
        element={
          userRollReducer.income.view_permission ? (
            <Incomes />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/loan"
        element={
          !userData ? (
            <Navigate to="/login" />
          ) : userRollReducer.loan.view_permission ? (
            <Loan />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/portfolio"
        element={
          !userData ? (
            <Navigate to="/login" />
          ) : userRollReducer.asset.view_permission ? (
            <Portfolio />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/setting"
        element={
          !userData ? (
            <Navigate to="/login" />
          ) : settingRedux.user_type === 1 ? (
            <Setting />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/emailverify" element={<EmailVerify />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
    </Routes>
  );
};

export default Router;

// rounded-[15px] border-[1px] border-[#E7E7E7] bg-white
// text-[10px] font-[400]
