import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icone } from "../../Assets/AssetsLog";
import { useSelector } from "react-redux";

const AddButton = (props) => {
  const userRollReducer = useSelector((state) => state.userRole.state);
  const [disable, setdisable] = useState(false);
  console.log(userRollReducer[props.name]);
  useEffect(() => {
    if (userRollReducer[props.name] !== undefined) {
      console.log(userRollReducer[props.name].save_permission);
      setdisable(!userRollReducer[props.name].save_permission);
    }
  }, [userRollReducer]);

  return (
    <Tooltip
      title={
        disable
          ? "You don't have permission to Add"
          : props.name
          ? "Add " + props.name
          : "Add"
      }
    >
      <span>
        <IconButton
          disabled={disable}
          {...props}
          sx={{
            backgroundColor: props.addbgcolor || "#F8F5FF",
          }}
        >
          <img src={Icone.PurplePlus} alt="" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default AddButton;
