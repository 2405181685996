// Icones
import FlagIcon from "./Icons/flag.svg";
import NotificationIcon from "./Icons/notification.svg";
import PlusIcon from "./Icons/plus.svg";
import SettingIcon from "./Icons/settings-filled.svg";
import DownArrow from "./Icons/DownArrow.svg";
import WalletGreenIcon from "./Icons/wallet-2.svg";
import WalletRedIcon from "./Icons/wallet.svg";
import BankIcon from "./Icons/bank.svg";
import MoneyBagIcon from "./Icons/moneybag.svg";
import HelpHandicon from "./Icons/helping-hand.svg";
import PercentageIcon from "./Icons/percentage.svg";
import DownloadIcon from "./Icons/download.svg";
import UploadIcon from "./Icons/upload.svg";
import Bag2Icon from "./Icons/bag-2.svg";
import PurplePlus from "./Icons/PurplePlus.svg";
import SearchNormalIcon from "./Icons/search-normal.svg";
import WalletAddIcon from "./Icons/wallet-add.svg";
import WalletAdd1Icon from "./Icons/wallet-add-1.svg";
import WalletAdd2Icon from "./Icons/wallet-add-2.svg";
import WalletAdd3Icon from "./Icons/wallet-add-3.svg";
import ArchiveIcon from "./Icons/archive.svg";
import Archive2Icon from "./Icons/archive (1).svg";
import PersonIcon from "./Icons/Person.svg";
import CalenderIcon from "./Icons/calendar-2.svg";
import PhoneCallIcon from "./Icons/phone-call.svg";
import CheckIcon from "./Icons/Check.svg";
import ClipIcon from "./Icons/Clip path group.svg";
import TimeIcon from "./Icons/time.svg";
import NoteIcon from "./Icons/note-edit-line.svg";
import UserIcone from "./Icons/Group 42.svg";
import EmailIcon from "./Icons/email-line.svg";
import LocationIcone from "./Icons/my-location.svg";
import LockIcone from "./Icons/lock.svg";
import CheckFillIcon from "./Icons/checkmark-filled.svg"
import ArrowbackIcon from "./Icons/arrow_back.svg"
import LogoutIcon from "./Icons/logout.svg"
import PersonalcardIcon from "./Icons/personalcard.svg"
import Transfer from "./Icons/Transfer.svg"
import NextIcon from "./Icons/next.svg"
import PreviousIcone from "./Icons/previous.svg"
import FilterIcon from "./Icons/Filter.svg"
import ExportIcone from "./Icons/file-export.svg"

// Logos
import ZLogo from "./Logo/Group251.svg";
import ZincoLogo from "./Logo/Zinco.svg";

// Images
import Frame19Img from "./Images/Frame 19.png";
import LoginImg from "./Images/loginimg.svg";
import SignUpImg from "./Images/SignupImg.svg";
import AboutImage from './Images/3.png'
import AssetImage from './Images/Frame 19.png';
import ExpireDateImage from './Images/Group 1414.svg'

const Icone = {
  FlagIcon,
  NotificationIcon,
  PlusIcon,
  SettingIcon,
  DownArrow,
  WalletGreenIcon,
  WalletRedIcon,
  BankIcon,
  MoneyBagIcon,
  HelpHandicon,
  PercentageIcon,
  DownloadIcon,
  UploadIcon,
  Bag2Icon,
  PurplePlus,
  SearchNormalIcon,
  WalletAddIcon,
  WalletAdd1Icon,
  WalletAdd2Icon,
  WalletAdd3Icon,
  ArchiveIcon,
  Archive2Icon,
  PersonIcon,
  CalenderIcon,
  PhoneCallIcon,
  CheckIcon,
  ClipIcon,
  TimeIcon,
  NoteIcon,
  UserIcone,
  EmailIcon,
  LocationIcone,
  LockIcone,
  CheckFillIcon,
  ArrowbackIcon,
  LogoutIcon,
  PersonalcardIcon,
  Transfer,
  NextIcon,
  PreviousIcone,
  FilterIcon,
  ExportIcone
};

const Logo = {
  ZincoLogo,
  ZLogo,
};

const Images = {
  Frame19Img,
  LoginImg,
  SignUpImg,
  AboutImage,
  AssetImage,
  ExpireDateImage
};

export { Icone, Logo, Images };
