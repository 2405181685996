import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userRoles } from "../Api/Organizations/organizationsApi";
// localStorage.setItem("isUserActivate", JSON.stringify({isUserExpired: false, date: ""}))
let isActive = JSON.parse(localStorage.getItem("isUserActivate"))

const initialState = {
    userDetails: {
        isUserExpired: false,
        date: ""
    }
}

export const callUserRoll = createAsyncThunk("fetchUserSettings", async (userTypeId) => {
    const res = await userRoles({
        user_type_id: userTypeId,
        type: "list"
    });
    return res.data;
});

export const userStateSclicer = createSlice({
    name: 'credential',
    initialState,
    reducers: {
        isActivateUser: (state, action) => {
            state.userDetails = action.payload
            // localStorage.setItem("isUserActivate", JSON.stringify({isUserExpired: action.payload.isUserExpired, date: action.payload.date}))
        }
    }
})

export const {isActivateUser} = userStateSclicer.actions;
export default userStateSclicer.reducer